.pricingTiers {
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
    padding: 0.5rem 1rem;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
    white-space: nowrap;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-right: -1rem;
}

.pricingTiers {
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
}

.pricingTiers::-webkit-scrollbar {
    display: none;
}

.pricingContent {
    display: flex;
    gap: 8px;
}



/* When has large weights, show end of content */
.scrollEnd {
    direction: rtl;
}

.scrollEnd .pricingContent {
    direction: ltr;
}

.flexGrow {

}

.pricingTiersLarge {

}


/* Hide scrollbar for Chrome/Safari */
.pricingTiers::-webkit-scrollbar {
    display: none;
}

.priceTier {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 0.5rem 0.5rem 27px;
    border-radius: 8px;
    min-width: 80px;
    flex: 0 0 auto; /* Prevent price tiers from shrinking */
    transition: all 0.2s ease;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: rgba(201,201,201,0.3);
}

.dark .priceTier {
    background-color: rgba(66,66,66,0.3);
}

.noSalePrice {
    padding-bottom: 0.5rem
}

.bigTierContent {
    width: 100%;
    justify-content: center !important;
}
.bigTierContentHalf {
    width: 50%;
}
.bigTierBox {
    width: auto;

}
.bigTierBoxRow {
    flex-direction: row;
    justify-content: space-evenly;
    gap: 1rem;
    width: auto;
    padding: 0.36rem 0.5rem 27px
}
.bigTierBoxHalf {
    width: 50%;
}

.priceTier .discountBadge {
    align-self: center;
}

.hiddenTitle {
    color: transparent;
    height: 0;
    font-size: 0.75rem;
}

.bigPricingBox {
    flex-basis: max-content;
    flex-grow: 1;
}

.selectedTier {
    border-color: var(--light-accent);
    background-color: var(--background-color);
}

.greyedOut {
    opacity: 0.3;
}

.weight {
    font-size: 0.75rem;
    margin-top: 0.25rem;
    color: var(--text-color);
}

.priceWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    margin-top: 3px;
}

.priceWrapOne {
    flex-direction: row;
    justify-content: space-between;
}

.price {
    font-size: 1rem;
    font-weight: bold;
    color: var(--branding-purple);
}

.priceDarkMode {
    color: var(--branding-green);

}

.originalPrice {
    font-size: 0.8rem;
    color: #888;
    text-decoration: line-through;
}

.regularPrice {
    color: var(--text-color-secondary);
}

.discountBadge {
    background-color: var(--discount-badge);
    background-color: var(--branding-purple-light);
    background-color: var(--branding-purple-light);
    color: var(--white-text);
    font-size: 0.63rem;
    font-weight: bold;
    padding: 0.2rem 0;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    text-align: center;
    margin-top: 0.25rem;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.discountBadgeDarkMode {
    background-color: var(--branding-purple-light);
}

@media screen and (min-width: 778px){
    .flexGrow {
        flex-grow: 1;
    }
    .pricingTiers {

    }
    .pricingContent {
        justify-content: end;
        justify-content: start;
    }
    .bigPricingBox {
        justify-content: end;
        justify-content: start;
    }
}

@media screen and (min-width: 588px) and (max-width: 778px) {
    .pricingTiers {
        justify-content: center;
    }
}

@media screen and (min-width: 1250px){
    .pricingTiers {
        justify-content: center;
        width: 100%;
    }
}
