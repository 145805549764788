.productList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 0
}

.productItem {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem;
    border-radius: 8px;
}

.productImage {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: var(--secondary-color);
    margin-right: 1rem;
}

.productInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.productName {
    height: 1rem;
    width: 99%;
    background-color: var(--secondary-color);
    margin-bottom: 0.5rem;
    border-radius: 12px;
}

.productCategory {
    height: 0.8rem;
    width: 81%;
    background-color: var(--secondary-color);
    margin-bottom: 0.5rem;
    border-radius: 12px;
}

.productMetadata {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.metadataItem {
    height: 0.8rem;
    width: 60px;
    background-color: var(--secondary-color);
    border-radius: 12px;
}

.priceInfo {
    height: 1.2rem;
    width: 30%;
    background-color: var(--secondary-color);
    border-radius: 12px;
}

.animatePulse {
    animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}
