.MapContainer {
    height: 300px !important;
    border-radius: 15px;
    position: absolute;
    width: 100%;
    top: 5px;
    bottom: 0;
}

.MapContainer > div {
    background-color: transparent !important;
}

@media screen and (min-width: 778px) {
    .MapContainer {
        height: calc(100vh - 140px)  !important;
    }

    .MapContainer > div {

    }
}

.MapContainer .leaflet-bottom {
    display: none !important;
}

.MapContainer .gmnoprint {
    display: none !important;
}

.ProfileMapContainer {
    height: 240px !important;
    position: relative;
}

/* Mapbox Styles */

.custom-marker {
    cursor: pointer;
    display: block;
    transform-origin: bottom;
    transition: transform 0.2s;
    will-change: transform;
}

.custom-marker:hover {
    transform: scale(1.1);
}

.user-location-marker {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.map-x {
    position: absolute;
    top: 6px;
    right: 6px;
}

.mapboxgl-popup {
    z-index: 1;
}

.mapboxgl-popup-content {
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.map-box-wrap {
    display: flex;
    text-decoration: none;
    color: inherit;
}

.map-logo-wrap {
    width: 60px;
    height: 60px;
    margin-right: 12px;
}

.map-logo-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.map-info-wrap {
    flex: 1;
}

.map-info-wrap h3 {
    margin: 0 0 5px;
    font-size: 16px;
    font-weight: 600;
}

.map-info-wrap p {
    margin: 0 0 5px;
    font-size: 14px;
    color: #666666;
}

/* Dark mode styles */
.mapboxgl-popup.dark .mapboxgl-popup-content {
    background-color: #242f3e;
    color: white;
}

.mapboxgl-popup.dark .mapboxgl-popup-tip {
    border-top-color: #242f3e;
    border-bottom-color: #242f3e;
}

.dark .map-info-wrap p {
    color: #8e8e93;
}

/* Dark mode styles */
.dark .mapboxgl-popup-content,
.mapboxgl-popup.dark .mapboxgl-popup-content {
    background-color: #242f3e !important;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.dark .mapboxgl-popup-tip,
.mapboxgl-popup.dark .mapboxgl-popup-tip {
    border-top-color: #242f3e;
    border-bottom-color: #242f3e;
}

.dark .map-info-wrap p,
.mapboxgl-popup.dark .map-info-wrap p {
    color: #8e8e93;
}

.dark .map-info-wrap h3,
.mapboxgl-popup.dark .map-info-wrap h3 {
    color: white;
}

.dark .map-info-wrap a,
.mapboxgl-popup.dark .map-info-wrap a {
    color: white;
    text-decoration: none;
}

/* Handle star rating colors in dark mode */
.dark .mapboxgl-popup-content svg path,
.mapboxgl-popup.dark .mapboxgl-popup-content svg path {
    stroke: rgba(255, 255, 255, 0.1);
}


.search-area-button {
    transition: all 0.2s ease;
    font-size: 14px;
}

.search-area-button:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.search-area-button.dark {
    background-color: #333;
    color: #fff;
    border: 1px solid #555;
}

.search-area-button.dark:hover {
    background-color: #444;
}

/* For the user location dot and ring */
.user-location-dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #4285F4;
    border: 2px solid #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.user-location-ring {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: rgba(66, 133, 244, 0.15);
    border: 2px solid rgba(66, 133, 244, 0.3);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
