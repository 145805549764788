.profileIconSquare {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer !important;
}

.iconSquare {
    width: 42px;
    height: 42px;
    border-radius: 9px;
    background: var(--secondary-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.iconSquare svg {
    font-size: 1.6rem;
    color: var(--text-color) !important;
}

.label {
    color: var(--text-color) !important;
    font-size: 0.75rem;
    margin-top: 3px;
}
