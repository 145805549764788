/* ProductList.module.css */
.productList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 0;
}

.productItem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem 0 0.5rem;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    position: relative;
    border-bottom: 1px solid var(--product-border);
}

.productItem:hover {
    background-color: var(--background-color);
    cursor: pointer;
}

.productInfoTop {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.productInfoBottom {
    display: flex;
    flex-direction: row;
    width: calc(100% + 2rem);
    margin-left: -1rem;
}

.productImageWrap {
    display: flex;
    height: 100%;
    justify-content: start;
    align-self: start;
}

.productImagePlaceholderWrap {
    display: flex;

    justify-content: start;
    align-self: start;
    width: 90px;
}

.productImage {
    display: flex;
    justify-content: start;
    width: 81px;
    height: 81px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 0.6rem;
}

.productImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.productInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.productName {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    color: var(--text-color-secondary);
}

.productCategory {
    margin: 0.2rem 0;
    font-size: 0.8rem;
    color: #666;
}

.productMetadata {
    display: flex;
    flex-wrap: wrap;
    gap: 0.15rem;
    margin-top: 0.2rem;
}

.brandName {
    font-size: 0.66rem;
    padding: 0.2rem 0;
    color: var(--text-color-secondary);
}

.dispensaryName,
.thcContent,
.productLabels,
.cbdContent {
    font-size: 0.66rem;
    padding: 0.2rem 0.5rem;
    border-radius: 6px;
    background-color: transparent !important;
    color: var(--text-color) !important;
    border: 1px solid var(--branding-purple);
}

.strainTypeLabel {
    font-size: 0.66rem;
}

.indica {
    border-color: var(--lineage-indica) !important;
}

.sativa {
    border-color: var(--lineage-sativa) !important;
}

.hybrid {
    border-color: var(--lineage-hybrid) !important;
}



.dispensaryName {
    background-color: #e6f4ea;
    color: #137333;
}

.thcContent {
    background-color: #fce8e6;
    color: #c5221f;
}

.cbdContent {
    background-color: #fff0b3;
    color: #b06000;
}

.priceInfo {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    font-size: 1.169rem;
}

.price {
    color: var(--text-color-secondary);
    font-weight: bold;
}

.originalPrice {
    color: #888;
    text-decoration: line-through;
    margin-right: 0rem;
    font-size: 0.66rem;
}
.priceSpans {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
}
.salePrice {
    color: var(--price-color);
    font-weight: bold;
}

.productActionsWrap {
    max-width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 9px;
    gap: 6px;
}

.discountBadge {
    background-color: var(--discount-badge);
    color: var(--black-text);
    font-size: 0.69rem;
    padding: 0.3rem 0.6rem;
    border-radius: 6px;
    display: flex;
    align-self: end
}

.productHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
}

.productName {
    margin: 0;
    flex-grow: 1;
}



.productName {
    margin: 0;
    flex-grow: 1;
}

.noDealsPinned {
    display: inline-block;
    text-align: center;
    width: 100%;
    padding: 6px 0;
    border-bottom: 1px solid var(--secondary-color);
}

.noDeals {
    display: inline-block;
    text-align: center;
    width: 100%;
    padding: 6px 0 6px;
    border-bottom: 1px solid var(--secondary-color);

}

.loading {
    height: 414px;
    opacity: 1;
    visibility: visible;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 1s ease, height 1s ease, visibility 0s linear 0s;
}

.loadingHide {
    height: 0;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 1s ease, height 1s ease, visibility 0s linear 1s;
    overflow: hidden;
}

.productLoader {
    height: auto;
    opacity: 1;
    visibility: visible;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 0;
    transition: opacity 1s ease, height 1s ease, visibility 0s linear 0s;
}

.productLoaderHide {
    height: 0;
    opacity: 0;
    visibility: hidden;
    padding: 0 1rem;
    transition: opacity 1s ease, height 1s ease, visibility 0s linear 1s;
    overflow: hidden;
}

.productLoaderContainer {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0 auto;
}

.loading {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.loadingHide {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
}
