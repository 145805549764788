/* Base map styles */
a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}
.mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-bottom-left {
    display: none !important;
}
.gmnoprint {
    display: none !important;
}
.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
    border: none !important;
}
.gmnoprint div {
    background:none !important;
}
.leaflet-bottom {
    display: none !important;
}

/* Custom marker styles */
.custom-marker {
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    width: 35px;
    height: 41px;
}

/* User location marker styles */
.user-location-marker {
    width: 20px;
    height: 20px;
    background-color: #98fb98;
    border-radius: 50%;
    border: 3px solid #333333;
}

.user-location-dot {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #98fb98;
    border-radius: 50%;
    animation: pulse 2s infinite;
}

.user-location-ring {
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border-radius: 50%;
    background-color: rgba(152, 251, 152, 0.3);
    animation: pulse-ring 2s infinite;
    pointer-events: none;
}

/* Popup styles */
.map-box-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    cursor: pointer;
}

.map-info-wrap {
    display: flex;
    flex-direction: column;
    color: #1a1a1a !important;
    justify-content: start;
    align-items: start;
    margin-top: 3px;
    cursor: pointer;
}

.map-logo-wrap {
    height: 45px;
    width: 45px;
    min-width: 45px;
    cursor: pointer;

}

.map-logo-wrap img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.mapboxgl-popup {
    z-index: 1;
}

.mapboxgl-popup-content {
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.map-box-wrap {
    display: flex;
    text-decoration: none;
    color: inherit;
}

.map-box-wrap.dark  {
    background: var(--secondary-color) !important;
}

.map-box-wrap button {
    background: transparent;
    border: none;
}

.map-logo-wrap {
    width: 60px;
    height: 60px;
    margin-right: 12px;
}

.map-logo-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.map-info-wrap {
    flex: 1;
}

.map-info-wrap h3 {
    margin: 0 0 5px;
    font-size: 16px;
    font-weight: 600;
}

.map-info-wrap p {
    margin: 0 0 5px;
    font-size: 14px;
    color: #666666;
}

.map-x {
    position: absolute;
    top: 6px;
    right: 6px;
    color: #151515;
}

.map-x path {
    stroke: #151515 !important;
    color: #151515;
}

.dark .map-x, .dark .map-x path {
    color: #fff;
    stroke: #fff !important;
}

.dark .map-info-wrap, .dark .map-info-wrap p, .dark .map-info-wrap h3 {
    color: #fff !important;
}

/* Dark mode styles */
.mapboxgl-popup.dark .mapboxgl-popup-content,
.dark .mapboxgl-popup-content {
    background-color: var(--secondary-color) !important;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.mapboxgl-popup.dark .mapboxgl-popup-tip,
.dark .mapboxgl-popup-tip {
    border-top-color: var(--secondary-color);
    border-bottom-color: var(--secondary-color);
}

.mapboxgl-popup.dark .mapboxgl-popup-anchor-right .mapboxgl-popup-tip,
.dark .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: var(--secondary-color);
    border-top-color: transparent;
    border-bottom-color: transparent;
}

.mapboxgl-popup.dark .mapboxgl-popup-anchor-left .mapboxgl-popup-tip,
.dark .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: var(--secondary-color);
    border-top-color: transparent;
    border-bottom-color: transparent;
}

.dark .map-info-wrap p,
.mapboxgl-popup.dark .map-info-wrap p {
    color: #8e8e93;
}

.dark .map-info-wrap h3,
.mapboxgl-popup.dark .map-info-wrap h3 {
    color: white;
}

.dark .map-info-wrap a,
.mapboxgl-popup.dark .map-info-wrap a {
    color: white;
    text-decoration: none;
}

.dark .mapboxgl-popup-content svg path,
.mapboxgl-popup.dark .mapboxgl-popup-content svg path {
    stroke: rgba(255, 255, 255, 0.1);
}

/* Animations */
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(0.9);
        opacity: 0.9;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes pulse-ring {
    0% {
        transform: scale(1);
        opacity: 0.8;
    }
    50% {
        transform: scale(1.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 0.8;
    }
}
