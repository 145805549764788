.MainNav {
    border-radius: 15px;
    padding: 5px 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0;
    left: 110px;
    width: 87%;
    z-index: 10;
    height: 45px;
    transition: transform 0.3s ease-in-out;
    z-index: 9999999;
}

.MainNav.usersettings {
    display: none;
}

.badge {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    position: absolute;
    top: -5px;
    left: 12px;
    background-color: var(--badge-accent);
    color: var(--white-text);
    border-radius: 50%;
    height: 20px;
    width: auto;
    min-width: 14px;
    padding: 0 3px;
    font-size: 11px;
    font-weight: bold;

}

.navItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--text-color);
    position: relative;
    gap: 0.25rem;
}

.navItem .iconWrap {
    color: var(--text-color);
    fill: var(--text-color);
    display: flex;
    align-items: center;
}

.navItem .iconWrap path {
    color: var(--text-color);
    fill: var(--text-color) !important;
}

.iconWrap {
    min-width: 18px;
}

.navItemActive {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--settings-icon);
    position: relative;
    gap: 0.25rem;
}

.navItemActive .iconWrap {
    color: var(--settings-icon);
    fill: var(--settings-icon) !important;
    display: inline-flex;
}

.navItemActive .iconWrap path {
    color: var(--settings-icon);
    fill: var(--settings-icon) !important;
}

.navDot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--settings-icon);
    margin-top: 4px;
}


.hidden {
    transform: translateY(-180%);
}

.visible {

}

@media screen and (max-width: 778px){
    .MainNav {
        background-color: var(--nav-background);
        box-shadow: 0 0 21px var(--branding-purple);
        border-radius: 15px;
        padding: 10px 15px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: fixed;
        bottom: 9px;
        left: 50%;
        top: auto;
        transform: translateX(-50%);
        width: 87%;
        z-index: 10;
        height: 45px;
        transition: transform 0.3s ease-in-out;
    }


    .navItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--text-color);
        position: relative;
        gap: 0;
    }

    .navItem .iconWrap {
        color: var(--text-color);
        fill: var(--text-color);
    }

    .navItem .iconWrap path {
        color: var(--text-color);
        fill: var(--text-color) !important;
    }

    .navItemActive {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--settings-icon);
        position: relative;
        gap: 0;
    }

    .badge {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        position: absolute;
        top: -5px;
        right: -5px;
        left: auto;
        background-color: var(--badge-accent);
        color: var(--white-text);
        border-radius: 50%;
        height: 20px;
        width: auto;
        min-width: 14px;
        padding: 0 3px;
        font-size: 11px;
        font-weight: bold;

    }

    .visible {
        transform: translateY(0) translateX(-50%);
    }

    .hidden {
        transform: translateY(180%) translateX(-50%);
    }
}
