.pullIndicator {
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    background: var(--pull);
    backdrop-filter: blur(8px);
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
}

.pullIndicatorNoText {
    top: -36px;
    transition: all 0.3s ease;
}

.pullIndicator:hover {
    opacity: 0.8;
}

.pullIndicator:active {
    transform: translateX(-50%) scale(0.95);
}

.pullBar {
    height: 4px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    cursor: pointer;
}

.pullText {
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    margin-top: 4px;
}

.pullTextFadeAway {

    display: none;
}

.chevronIcon {
    color: white;
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
}
