/* NavbarFilter.module.css */
.navbarFilterWrapper {
    width: 100%;
    z-index: 6;
    position: relative;
}

.filtersOpened {
    z-index: 999999999;
}

.navbar {
    display: flex;
    align-items: center;
    background-color: var(--branding-purple);
    width: 94%;
    height: 54px;
    left: 3%;
    position: relative;
    border-radius: 12px;
    overflow: visible;
}

.greenNavbar {
    background-color: var(--branding-green);
}

.purpleNavbar {
    background-color: var(--dark-secondary);
}

.navbarPinned {
    background-color: transparent !important;
}

@media screen and (min-width: 778px){
    .navbarPinned {
        width: 100%;
        justify-self: end;
        left: 0;
    }
}

.selectedWrap {
    display: flex;
    align-items: center;
}

.listboxContainer {
    position: relative;
    display: flex;
    align-items: center;
}

.listboxContainer [data-state='open'] > div {

}

.listboxContainer ul {
    list-style: none;
    padding: 0;
}

.listboxButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 63px;
    height: 54px;
    background: none;
    border: none;
    cursor: pointer;
    color: #fff;
}

.greenListboxButton {
    color: #333;
}

.selectedIcon {
    width: 27px;
    height: 27px;
    flex-shrink: 0;
    margin-right: 6px;
    fill: var(--white-text);
}

.menuIcon {
    width: 24px;
    height: 24px;
    fill: var(--white-text);
}




.listboxOptions {
    position: absolute;
    top: calc(100% - 15px);
    left: 0;
    width: 243px;
    margin-top: 4px;
    overflow: auto;
    background-color: var(--branding-purple);
    color: var(--white-text);
    border-radius: 12px;
    box-shadow: 0 10px 38px -10px rgba(22, 23, 24, 0.35),
    0 10px 20px -15px rgba(22, 23, 24, 0.2);
    max-height: 360px;
    z-index: 50;
    animation: slideDownAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1);
}

.greenListboxOptions {
    background-color: var(--branding-green);
}

.purpleListboxOptions {
    background-color: var(--dark-secondary);
}

@keyframes slideDownAndFade {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.listboxOptions[data-state='open'] {
    animation: slideDownAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1);
}

.listboxContainer :global([role="navigation"]) {
    display: contents;
}

.listboxContainer :global([role="list"]) {
    display: contents;
}


.listboxOption {
    padding: 8px 12px;
    cursor: default;
    display: flex;
    align-items: center;
    border: none !important;
    background-color: transparent;
    width: 100%;
    font-size: 18px;
    color: var(--white-text);
    cursor: pointer;
}

.listboxOption svg {
    color: var(--white-text);
    fill: var(--white-text);
}

.greenListboxOption {
    color: var(--light-text);
}

.greenListboxOption svg {
    color: var(--light-text);
    fill: var(--light-text);
}

.listboxOption:hover {
    background-color: var(--dark-thirdary);
    color: var(--white-text);
}

.greenListboxOption:hover svg {
    color: var(--white-text);
    fill: var(--white-text);
}





.optionText {
    margin-left: 8px;
}

.selectedOption {
    font-weight: 500;
}

.selectedFilter {
    display: flex;
    align-items: center;
    margin-left: 0;
    color: var(--white-text);
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px 0;
    font-size: 18px;
}

.greenSelectedFilter {
    color: #333;
}

.lottieSvg {
    width: 45px !important;
    height: 50px !important;
    overflow: hidden;
}
.lottieSvgMenu {
    height: 36px !important;
    width: 33px !important;
    overflow: hidden;
}

.lottieSvg path {
    fill: #fff !important;
}

.lottieSvg mask path {
    fill: #000 !important;
}
.lottieSvg mask path:nth-child(5) {
    fill: #fff !important;
}

.lottieSvgMenu path {
    fill: #fff !important;
}

.lottieSvgMenu mask path {
    fill: #000 !important;
}

.lottieSvgMenu mask path:nth-child(5) {
    fill: #fff !important;
}

.greenLottieIcon path {
    fill: var(--light-text) !important;
}

.greenActiveOption .greenLottieIcon path {
    fill: #fff !important;
}

.icon {
    width: 27px;
    height: 27px;
    flex-shrink: 0;
    margin-right: 6px;
}



.listboxIcon {
    width: 27px;
    height: 27px;
    flex-shrink: 0;
    margin-right: 6px;
}

.greenMenuIcon, .greenMenuIcon .cls-1 {
    color: var(--light-text);
    fill: var(--light-text);
}

.activeOption {
    background-color: var(--dark-secondary);
    color: var(--white-text);
}

.greenActiveOption, .activeIcon {
    color: var(--white-text) !important;
    fill: var(--white-text) !important;
}

.purpleActiveOption {
    background-color: var(--dark-thirdary);
}

/*
Search Styles
 */


.searchContainer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 0;
    overflow: hidden;
    transition: width 0.3s ease-in-out;
    display: flex;
    align-items: center;
}

.searchContainer.visible {
    width: 100%;
}

.searchInput {
    position: absolute;
    right: 46px;
    border-radius: var(--border-radius-xl) !important;
    width: calc(100% - 142px) !important;
    height: 80%;
    border: none;
    background-color: var(--branding-purple);
    color: #fff;
    padding: 0 16px;
    font-size: 16px;
}

.searchInput::placeholder {
    color: rgba(0, 0, 0, 0.3);
}

.iconButtonSearchVisible {
    color: var(--black-text);
}

.iconButtonSearch {
    z-index: 9999;
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    margin-left: auto;
    color: var(--white-text);
    position: absolute;
    right: 39px;
}

.iconButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    margin-left: auto;
    color: var(--white-text);
}

.greenIcon {
    color: var(--light-text);
}

.fullFilterContainer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    background-color: var(--branding-purple);
    z-index: 999999;
    padding: 20px;
    overflow-y: auto;
    transition: all 0.3s ease-in-out;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.fullFilterVisible {
    transform: translateY(0);
}

.fullFilterWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
}


.filterHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
}

.filterHeader h2 {
    margin: 0;
    font-size: 24px;
}

.closeButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
}

.closeIcon {
    width: 24px;
    height: 24px;
    color: var(--text-color);
}

.filterSection {
    margin-bottom: 20px;
}

.filterOptions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.filterOptions button {
    background-color: #f0f0f0;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.filterOptions button:hover {
    background-color: #e0e0e0;
}

.applyFilters {
    background-color: var(--branding-purple);
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-top: 20px;
}

.applyFilters:hover {
    opacity: 0.9;
}

.activeFiltersContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 8px 0;
    width: 94%;
    position: relative;
    left: 3%;
}

.activeFiltersLeft {
    justify-content: flex-start !important;
    width: 100%;
    left: 0;
}

@media screen and (min-width: 778px) {
    .activeFiltersContainer {
        justify-content: end;
    }

    .fullFilterContainer {
        left: auto;
        width: 48%;
    }

}



.activeFilter {
    display: flex;
    align-items: center;
    border: 1px solid var(--branding-purple);
    color: var(--text-color);
    padding: 4px 8px 4px 16px;
    border-radius: 9px;
    font-size: 14px;
}

.activeFilterNeutral {
    border: 1px solid var(--white-text) !important;
    color: var(--white-text);
}

.activeFilterNeutral button svg {
    color: var(--white-text);
}

.activeFilterPinned {
    background-color: var(--branding-purple);
    color: var(--white-text);
}



.activeFilter button {
    background: none;
    border: none;
    color: var(--text-color);
    margin-left: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.activeFilterPinned button {
    color: var(--white-text);
}

.activeFilterButton {
    background-color: var(--branding-green) !important;
    color: var(--black-text) !important;
}
.greenActiveFilterButton {
    background-color: var(--branding-purple) !important;
    color: var(--white-text) !important;
}

.disabledFilterButton {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: #e0e0e0 !important;
    color: #888 !important;
}

.disabledFilterButton:hover {
    background-color: #e0e0e0 !important;
}

.rangeSlider {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #ccc;
    margin-top: 30px;
    margin-bottom: 20px;
}

.rangeProgress {
    position: absolute;
    height: 100%;
    background-color: #6366f1;
    z-index: 1;
}

.rangeInput {
    position: absolute;
    top: -11px; /* Adjust this value to center the thumb on the bar */
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    pointer-events: none;
    z-index: 2;
}


.rangeInput::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #6366f1;
    cursor: pointer;
    pointer-events: auto;
    z-index: 3;
}

.rangeInput::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #6366f1;
    cursor: pointer;
    pointer-events: auto;
    z-index: 3;
}

.rangeValues {
    display: block;
    text-align: left;
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
}

.filterSection h3 {
    margin-bottom: 10px;
}

.fullFilterContainer {
    background-color: var(--branding-purple);
    color: #fff;
}

.greenFullFilter {
    background-color: var(--light-text);
    color: var(--white-text);
}



/* New styles for the inner circle */
.rangeInput::-webkit-slider-thumb::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
}

.rangeInput::-moz-range-thumb::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
}



.applyButtonContainer {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 50%;
    bottom: 0;
    z-index: 99;

    height: 60px;
    background-color: var(--branding-green);
    border-radius: 15px;
    justify-self: flex-end;

}

.greenApplyButtonContainer {
    background-color: var(--branding-purple);
}

.applyButton {
    background-color: var(--branding-green);
    color: var(--black-text);
    border: none;
    border-radius: 30px;
    padding: 10px 30px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    height: 42px;
    transition: background-color 0.2s ease;
    margin: auto;
    width: 100%;
    border-radius: 12px;
}

.greenApplyButton {
    background-color: var(--branding-purple);
    color: var(--white-text);
}

.applyButton:hover {
    background-color: var(--branding-green);
}
.greenApplyButton:hover {
    background-color: var(--branding-purple);
}

.indica {
    border: 1px solid var(--lineage-indica);
    border-color: var(--lineage-indica) !important;
    background-color: transparent !important;

}

.sativa {
    border: 1px solid var(--lineage-sativa) !important;
    border-color: var(--lineage-sativa) !important;
    background-color: transparent !important;
}

.hybrid {
    border: 1px solid var(--lineage-hybrid) !important;
    border-color: var(--lineage-hybrid) !important;
    background-color: transparent !important;

}

.indicaPinned {
    background-color: var(--lineage-indica) !important;
    color: var(--white-text);
}

.indicaPinned button {
    color: var(--white-text);
}

.sativaPinned {
    background-color: var(--lineage-sativa) !important;
    color: var(--white-text);

}
.sativaPinned svg {
    color: var(--white-text) !important;

}
.hybridPinned {
    background-color: var(--lineage-hybrid) !important;
    color: var(--white-text) !important;
}

.hybridPinned svg {
    color: var(--white-text) !important;

}

.hybrid button {

}

@media screen and (max-width: 778px) {
    .fullFilterWrap {
        width: 100%;
        position: absolute;
        padding: 0 0 100px;
        height: 100%;
        overflow-y: auto;
    }

    .filterContent {
        height: 100%;
        padding: 15px 18px 510px;
    }

    .fullFilterContainer {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        height: 75vh;
        overflow: hidden;
        transform: translateY(200%);
        background-color: var(--branding-purple);
        z-index: 999999;
        padding: 0 0 60px;
        transition: all 0.3s ease-in-out;
    }

    .fullFilterVisible {
        transform: translateY(0);
    }

    .applyButtonContainer {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-radius: 0;
    }

}
