.productActions {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.likeButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;
}

.liked {
    color: var(--light-accent) !important;
}

.notLiked {
    color: var(--branding-purple);
}

.voteButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
}

.likeShareContainer {
    display: flex;
    align-items: center;
    padding: 4px 0;
    justify-content: space-between;
    gap: 6px;
}

.likeShareContainer button {
    width: 39px;
    height: 39px;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.likeShareContainer svg {
    font-size: 1.26rem;
    color: var(--text-color-secondary);
}

.likeShareBtn {
    border: none;
    cursor: pointer;
    padding: 0;
    color: var(--text-color-secondary);
    display: flex;
    align-items: center;
    background-color: var(--pill-bg);
}

.voteContainer {
    display: flex;
    align-items: center;
    border-radius: 9px;
    padding: 3px 6px;
    justify-content: space-around;
    background-color: rgba(75,75,75,0.3);
}

.voteButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: var(--text-color-secondary);
    display: flex;
    align-items: center;
}

.voteScore {
    font-size: 1rem;
    font-weight: bold;
    margin: 0 8px;
    color: var(--text-color-secondary);  /* Reddit's upvote color */
}

.voteScorePositive {
    font-size: 1rem;
    font-weight: bold;
    margin: 0 3px;
    color: var(--vote-green);
}

.voteScoreNegative {
    font-size: 1rem;
    font-weight: bold;
    margin: 0 3px;
    color: var(--light-accent);
}

.upvoted {
    color: var(--vote-green);
}

.downvoted {
    color: var(--light-accent);  /* A blue color for downvotes */
}
