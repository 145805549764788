.container {
    background-color: var(--background-color);
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    max-width: 42rem;
    margin: 0 auto;
    padding-bottom: 90px;
}

.header {
    padding: 1rem;
    border-bottom: 1px solid var(--secondary-color);
}

.headerTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-size: 1.25rem;
    font-weight: 600;
}

.closeButton {
    padding: 0.5rem;
    border-radius: 9999px;
    transition: background-color 0.2s;
    background-color: var(--thirdary-color);
}

.closeButton:hover {
    background-color: var(--secondary-color);
}

.closeIcon {
    width: 1.25rem;
    height: 1.25rem;
    color: var(--text-color);
}

.headerBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}

.tabs {
    display: flex;
    gap: 1rem;
}

.tab {
    padding: 0.5rem 0.25rem;
    color: var(--text-color);
    border-bottom: 2px solid transparent;
    transition: all 0.2s;
}

.activeTab {
    color: #2563eb;
    border-bottom-color: #2563eb;
}



.markRead {
    color: #2563eb;
    padding: 9px 16px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    color: var(--text-color);
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: var(--secondary-color);
}

.notificationsContainer {
    max-height: 600px;
    overflow-y: auto;
}

.dateHeader {
    padding: 0.5rem 1rem;
    background-color: var(--secondary-color);
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--text-color);
    text-transform: capitalize;
}

.notificationsList {
    padding: 0;
}

.notificationItem {

    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 0;
    border-bottom: 1px solid var(--secondary-color);
}

.notificationItem:last-child {
    border-bottom: none;
}
.avatarWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.avatar {
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 9999px;
    background-color: var(--branding-purple);
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.125rem;
}

.notificationContent {
    flex: 1;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
}

.notificationText {
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.notificationTitle {
    font-weight: bold;
}

.notificationMeta {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.25rem;
}

.notificationTag {
    background-color: var(--branding-purple);
    color: var(--white-text);
    padding: 0.125rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    text-transform: capitalize;
}

.notificationTime {
    color: var(--text-color);
    font-size: 0.875rem;
}

.loader {
    display: flex;
    justify-content: center;
    padding: 1.5rem;
}

.loaderIcon {
    animation: spin 1s linear infinite;
    color: var(--text-color);
    width: 1.5rem;
    height: 1.5rem;
}

.error {
    margin: 1rem;
    padding: 1rem;
    background-color: #fee2e2;
    border-radius: 0.375rem;
    color: #dc2626;
}

.noMore {
    text-align: center;
    padding: 1rem;
    color: var(--text-color);
    font-size: 0.875rem;
}

.emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 1rem;
}

.emptyIcon {
    width: 3rem;
    height: 3rem;
    color: #9ca3af;
    margin-bottom: 1rem;
}

.emptyText {
    color: #6b7280;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}



@media (max-width: 640px) {
    .container {
        margin: 0;
        border-radius: 0;
    }

    .notificationsContainer {
        max-height: calc(100vh - 120px);
    }
}

/* SWIPE TO DELETE */
.notificationItemWrapper {
    cursor: pointer;
    position: relative;
    overflow: hidden; /* ensures the delete button won't visually bleed outside if we shift content */
}

.deleteX {
    display: none;
}
@media screen and (min-width: 747px){
    .deleteX {
        display: inline-block;
        position: absolute;
        top: 6px;
        right: 6px;
        z-index: 3;
        cursor: pointer;
    }
    .deleteX svg {
        cursor: pointer;
    }
}


/* Container that holds the actual notification content to be swiped */
.notificationSwipeContainer {
    display: flex;
    padding: 1rem;
    transition: transform 0.2s ease-out;
    z-index: 2;
    gap: 6px;
    position: relative;
}

.dealSheetOpen {
    pointer-events: none;
}

.seen {
    background-color: var(--background-color);
}

.unseen {
    background-color: var(--branding-purple);
    border-bottom: 1px solid #fff;
}

/* The delete button itself */
.deleteButton {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 70px;
    background-color: #ff3b30; /* iOS-style red */
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    z-index: 1;
}

/* Example: text or icon in the delete button */
.deleteButton span {
    pointer-events: none; /* so the entire button is clickable */
}
