/* ProductList.module.css */
.productList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 0;
}

.productItem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem 0 0.5rem;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    position: relative;
    border-bottom: 1px solid var(--product-border);
}

.productItem:hover {
    background-color: var(--product-bg-transparent);
    cursor: pointer;
}

.productInfoTop {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.productInfoBottom {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.productInfoBottomNoMargin {

}

.productImageWrap {
    display: flex;
    height: 100%;
    justify-content: start;
    align-self: start;
    flex-direction: column;
    align-items: center;
    gap: 6px;
}



.productImagePlaceholderWrap {
    display: flex;

    justify-content: start;
    align-self: start;
    width: 96px;
}

.productImage {
    display: flex;
    justify-content: start;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    margin-right: 0.6rem;
    position: relative;
}

.productImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;

}



.productInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.productName {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    color: var(--text-color-secondary);
}

.productCategory {
    margin: 0.2rem 0;
    font-size: 0.8rem;
    color: #666;
}

.productMetadata {
    display: flex;
    flex-wrap: wrap;
    gap: 0.15rem;
    margin-top: 0.2rem;
}

.brandName {
    font-size: 0.66rem;
    padding: 0.2rem 0;
    color: var(--text-color-secondary);
}

.dispensaryName,
.dispensaryDistance,
.thcContent,
.productLabels,
.cbdContent {
    font-size: 0.66rem;
    padding: 0.3rem 0.5rem;
    border-radius: 9999px;
    background-color: var(--pill-bg) !important;
    color: var(--text-color) !important;
    border: 0px solid var(--text-color-secondary);
}

.dispensaryDistance {
    font-size: 12px;
    width: auto;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -10px;
    z-index: 1;
    right: 0;
    left: 0;
    margin: auto;
    color: var(--white-text) !important;
    background-color: var(--branding-purple) !important;
    font-weight: bold;
}

.strainTypeLabel {
    font-size: 0.66rem;
}

.indica {
    border-color: var(--lineage-indica) !important;
    background-color: var(--lineage-indica) !important;
    color: var(--white-text) !important;
}

.sativa {
    border-color: var(--lineage-sativa) !important;
    background-color: var(--lineage-sativa) !important;
    color: var(--white-text) !important;

}


.hybrid {
    border-color: var(--lineage-hybrid) !important;
    background-color: var(--lineage-hybrid) !important;
    color: var(--white-text) !important;
}




.dispensaryName {
    background-color: #e6f4ea;
    color: #137333;
}

.thcContent {
    background-color: #fce8e6;
    color: #c5221f;
}

.cbdContent {
    background-color: #fff0b3;
    color: #b06000;
}

.priceInfo {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    font-size: 1.169rem;
    justify-content: center;
}



.price {
    color: var(--text-color-secondary);
    font-weight: bold;
}

.originalPrice {
    color: #888;
    text-decoration: line-through;
    margin-right: 0rem;
    font-size: 0.66rem;
}
.priceSpans {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
}
.salePrice {
    color: var(--price-color);
    font-weight: bold;
}

.productActionsWrap {
    max-width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 9px;
}
.dateUpdated {
    font-size: 0.69rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.75rem;
}
.discountWrap {
    margin: 6px 0 0 0;
}
.discountBadge {
    font-size: 0.81rem;
    border-radius: 6px;
    display: flex;
    align-self: end;
    font-weight: bold;
}

.discountWrapTop {
    padding: 0;
    justify-content: center;
    display: flex;
}

.discountWrap:last-child {
    padding-right: 0;
}

.discountBadgeTop {
    inline-size: max-content;
    inline-size: fit-content;
    justify-self: start;
    border-radius: 15px !important;
}

.productHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
}

.productName {
    margin: 0;
    flex-grow: 1;
}



.productName {
    margin: 0;
    flex-grow: 1;
}

.noDeals {
    display: inline-block;
    text-align: center;
}

.loading {
    height: 24px;
    opacity: 1;
    visibility: visible;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: opacity 1s ease, height 1s ease, visibility 0s linear 0s;
}

.loadingHide {
    height: 0;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: opacity 1s ease, height 1s ease, visibility 0s linear 1s;
    overflow: hidden;
}

.productLoader {
    height: auto;
    opacity: 1;
    visibility: visible;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 0;
    transition: opacity 1s ease, height 1s ease, visibility 0s linear 0s;
}

.productLoaderHide {
    height: 0;
    opacity: 0;
    visibility: hidden;
    padding: 0 1rem;
    transition: opacity 1s ease, height 1s ease, visibility 0s linear 1s;
    overflow: hidden;
}

@media screen and (min-width: 778px) {
    .productItem {
        padding: 0.5rem;
        overflow: hidden;
    }
    .priceInfo {
        flex-direction: row;
    }

    .discountWrap {
        margin-left: 0rem
    }

    .discountWrapTop {
        padding: 0;
    }

    .discountBadgeTop {
        justify-self: start;
    }
}

