.mapLoader {
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
}

.mapBackground {
    width: 100%;
    height: 100%;
    background-color: #242f3e;
    position: relative;
}

.road {
    position: absolute;
    background-color: #3c4e66;
}

.horizontal {
    width: 100%;
    height: 4px;
    top: 50%;
}

.vertical {
    width: 4px;
    height: 100%;
    left: 50%;
}

.pin {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--branding-purple);
    position: absolute;
    transform: translate(-50%, -50%);
}

.label {
    width: 40px;
    height: 8px;
    border-radius: 4px;
    background-color: #4a5d78;
    position: absolute;
}

.animatePulse {
    animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

@media screen and (min-width: 778px) {
    .mapLoader {
        height: calc(100vh - 160px)
    }
}
